import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"

const PublicationsPage = ({data}) => (
  <Layout pageInfo={{ pageName: "publication" }} active="publication">
    <SEO title="Publications" keywords={[`unhistoried`, `publication`, `brrrlabs`]} />
    <Container className="publications-page">
      <Row noGutters className="unhistoried-publications-row">
        {data.allContentfulPublication.nodes.map((item, index) => {
          return (
            <Col md={4} className="unhistoried-publications-col">
              <Link to={"/publication/"+item.publicationTitle}>
              <div className="unhistoried-publications-item">
                <h4 className="unhistoried-publications-title">{item.publicationTitle}</h4>
                <GatsbyImage image={item.publicationFeaturedImage.gatsbyImageData}  />
              </div>
              </Link>
            </Col>
          )
        })}
      </Row>
    </Container>
  </Layout>
)

export const publicationQuery = graphql`
query PublicationQuery {
  allContentfulPublication(sort: {order: DESC, fields: createdAt}) {
    nodes {
      publicationImages {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
      publicationFeaturedImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
      publicationDescription {
        childMarkdownRemark {
          html
        }
      }
      publicationTitle
    }
  }
}`

export default PublicationsPage
